<template>
  <div v-if="project" class="flex flex-col h-screen">
    <div class="bg-green-600 py-5">
      <div class="text-white text-3xl font-bold mx-print">ASKA</div>
    </div>
    <!-- <img style="width:200px;margin-left:50px" src="../assets/moto.png" /> -->
    <div class="text-right my-5 mx-print">
      Nr. {{ project.data.number }} din
      {{ project.data.createdAt.toDate() | date }}
    </div>
    <div class="my-5 mx-print">
      <div class="uppercase font-bold text-2xl">
        Ofertă de Preț
      </div>
      <div class="text-xl">
        {{ client ? client.data.name : "???" }}
      </div>
    </div>
    <div class="flex-1 my-5 mx-print">
      <div class="mb-5">
        Vă prezentam în continuare oferta de preț pentru
        <b>{{ project.data.name }}</b>
      </div>
      <div>
        <span class="inline-block w-16 font-bold">Tiraj</span>
        {{ quantity | number }}
      </div>
      <div>
        <span class="inline-block w-16 font-bold">Format</span>
        {{ project.data.variables.width }} x
        {{ project.data.variables.height }} mm
      </div>
      <div
        v-for="(component, id, index) in project.data.components"
        :key="id"
        class="my-5"
      >
        <div class="underline font-bold text-xl mb-3">
          {{ index + 1 }}. Tipar {{ component.name }}
        </div>
        <div>
          <span class="inline-block w-16 font-bold">Hartie</span>
          {{
            materialCategoriesById[component.request.sheetCategoryId].data.name
          }}
          {{ component.request.sheetWeight }}g
        </div>
        <div>
          <span class="inline-block w-16 font-bold">Tipar</span>
          {{ component.request.pages }} pagini ({{
            component.request.frontColors
          }}
          + {{ component.request.backColors }})
        </div>
        <div v-if="component.request.varnish">
          <span class="inline-block w-16 font-bold">Lacuire</span>
          {{ component.request.varnish ? "da" : "nu" }}
        </div>
        <div v-if="component.request.lamination">
          <span class="inline-block w-16 font-bold">Foliere</span>
          {{ component.request.lamination ? "da" : "nu" }}
        </div>
      </div>
      <div class="underline font-bold text-xl my-5">
        {{ Object.values(project.data.components).length + 1 }}. Servicii
        Suplimentare
      </div>
      <ul class="list-inside list-disc">
        <li v-for="task in extraTasks" :key="task.id">{{ task.name }}</li>
      </ul>
      <div class="underline font-bold text-xl my-5">
        {{ Object.values(project.data.components).length + 2 }}. Mentiuni
      </div>
      <textarea
        class="w-full h-20"
        placeholder="Trece mentiunile aici"
      ></textarea>
    </div>
    <div class="font-bold text-2xl my-5 mx-print">
      Pret
      {{ project.data.price | number }} lei ({{ unitPrice | number }}
      lei / buc)
    </div>
    <div class="bg-green-600 text-white text-center py-5">
      <p>
        RO 5379097, J24/367/1994, Sighetu Marmației, 435500, Maramureș, România
      </p>
      <p>www.aska.ro</p>
    </div>
  </div>
</template>

<script>
import { keyBy } from "lodash";
export default {
  props: ["id"],
  data() {
    return {
      project: null,
      client: null,
      materialCategories: null
    };
  },
  computed: {
    quantity() {
      return this.project.data.variables.quantity;
    },
    unitPrice() {
      return this.project.data.price / this.quantity;
    },
    extraTasks() {
      const tasks = Object.values(this.project.data.tasks);
      return tasks.filter(task => !task.parent);
    },
    materialCategoriesById() {
      return keyBy(this.materialCategories, "id");
    }
  },
  async created() {
    this.materialCategories = await this.$fetch(
      this.$firestore().collection(`materialCategories`)
    );
    this.project = await this.$fetch(
      this.$firestore().doc(`projects/${this.id}`)
    );
    if (this.project.data.clientId) {
      this.client = await this.$fetch(
        this.$firestore().doc(`clients/${this.project.data.clientId}`)
      );
    }
    // this.$nextTick(() => {
    //   window.print();
    // });
  }
};
</script>

<style>
body {
  margin: 0;
  background: white;
  -webkit-print-color-adjust: exact;
}
.mx-print {
  margin-left: 2cm;
  margin-right: 2cm;
}
@page {
  size: A4 portrait;
  margin: 0;
}
</style>
